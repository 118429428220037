<!-- 登录组件 -->
<template>
  <div class="login-wrap" :lang="$i18n.locale">
		<el-header class="login-header">
			<!-- logo -->
			<div class="login-logo">
				<img src="../assets/images/login_logo.png" />
			</div>
			<!-- zpy 2022-7-21 VIP国际化 选择语言 -->
			<el-dropdown style="margin-right: 15px; float:right;font-size: 18px;color: white;" @command="handleCommand">
				<span class="el-dropdown-link">{{$t('setting.languageType')}}<i class="el-icon-arrow-down el-icon--right"></i></span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item command="zh">Zh - 中文</el-dropdown-item>
					<el-dropdown-item command="en">En - English</el-dropdown-item>
					<el-dropdown-item command="ar">Ar - بالعربية</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</el-header>
		<div class="login_slogan">
			<div class="slogan_title">
				<label>{{$t('loginPage.sloganTitle_first')}}</label>
				<i v-if="$i18n.locale == 'zh'"></i>
				<label>{{$i18n.locale != 'ar'?$t('loginPage.sloganTitle_last'):''}}</label>
			</div>
			<div class="slogan_content">
				<span>{{$t('loginPage.slogan_content')}}</span>
			</div>
		</div>
    <el-form :model="loginUser" :rules="rules" ref="loginUser" class="container" style>
      <!-- <div
        class="layadmin-user-login-box layadmin-user-login-header"
        style="width: 326px; font-size:36px;display:flex;justify-content:center;align-items:center;margin-bottom:10px"
      >
        <img src="../assets/images/FleeTan.png" alt style="width:70%" />
      </div> -->
			<div class="login_title">
				<span>{{$t('loginPage.welcomeLogin')}}</span>
			</div>
      <el-form-item prop="user_name">
        <el-input v-model="loginUser.user_name" prefix-icon="el-icon-user" :placeholder="$t('loginPage.loginName_ph')"></el-input>
      </el-form-item>
      <el-form-item prop="login_password">
        <el-input
          v-model="loginUser.login_password"
          show-password
          prefix-icon="el-icon-unlock"
          :placeholder="$t('loginPage.loginPwd_ph')"
          @keyup.enter.native="submitForm('loginUser')"
        ></el-input>
      </el-form-item>

      <!-- <el-form-item style="margin-bottom:8px">
        <router-link :to="'/changePass'" tag="a" class="forget_password">忘记密码？</router-link>
      </el-form-item>-->

      <el-form-item>
        <el-button
          type="primary"
          class="login-btn enter"
          @click="submitForm('loginUser')"
        >{{$t('loginPage.loginBtn')}}</el-button>
      </el-form-item>
      <!-- <el-form-item style="text-align:right">
        <span style="font-size:14px">Powered By</span>
        <img
          src="@/assets/images/logo.jpg"
          alt
          style="width:30%;margin-left:10px;vertical-align: middle;"
        />
      </el-form-item> -->
      <el-form-item class="typecap" style="margin-bottom:12px;display:none;">
        <div class="flexBet">
          <el-input
            class="code"
            prefix-icon="el-icon-key"
            v-model="loginUser.code"
            placeholder="请输入验证码"
            @keyup.enter.native="submitForm('loginUser')"
          ></el-input>
          <div class="indentify flexStart" @click="getImage">
            <!-- <Identify :defaultCode="idenntifyCode" /> -->
            <img class="image" src alt />
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import Identify from "@/components/Identify.vue";
import { changeLanguage } from '@/assets/i18n/index'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    Identify
  },
  data() {
    //这里存放数据
    return {
      loginUser: {
        user_name: "",
        login_password: "",
        code: ""
      },
      //验证码
      idenntifyCodes: "124567890abcdefghjkmnpqrst",
      idenntifyCode: "",
      uuid: "" // 传递到后端的值
    };
  },
	computed:{
		rules() {// 表单验证需要在计算属性定义，才能实现实时更新国际化提示信息
		  //自定义验证规则 暂不需要验证码
		  // const validateCode = (rule, value, callback) => {
		  //   if (value === "") {
		  //     callback(new Error("请输入验证码"));
		  //   } else {
		  //     if (value != this.idenntifyCode) {
		  //       callback(new Error("验证码输入不正确"));
		  //     }
		  //     callback();
		  //   }
		  // };
			return {
				user_name: [
				  { required: true, message: this.$t('loginPage.loginName_ph'), trigger: "blur", }
				],
				login_password: [
				  { required: true, message: this.$t('loginPage.loginPwd_ph'), trigger: "blur" }
				],
				// code: [{ validator: validateCode, trigger: "blur" }]
			}
		}
	},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          // if (this.loginUser.code.length === 0) {
          //   return this.$message.warning("请输入验证码");
          // }
          //请求数据
          let params = {
            username: this.loginUser.user_name,
            password: this.loginUser.login_password,
            code: this.loginUser.code,
            uuid: this.uuid
          };
          // console.log(params);
          //将对象转换成JSON字符串
          var oParams = JSON.stringify(params);
          // console.log(oParams);
          // 将JSON字符串存储到session中
          window.sessionStorage.setItem("params", oParams);

          const res = await this.$http.post("/api/login", params);
          if (res.code == "500") {
            this.$message.error(res.msg);
            this.getImage();
          } else if (res.code == "-2") {
            this.$message.error(this.$t('loginPage.loginErrorInfo'));
          } else if (res.code == "200") {
            window.sessionStorage.setItem("token", res.token);
            this.$router.push({ path: "/" });
            this.$message.success(this.$t('loginPage.loginSuccessInfo'));
          } else {
            this.$message.error(this.$t('loginPage.loginOtherError'));
          }
        }
      });
    },
    //生成一个随机数 Identify.vue
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.idenntifyCode = "";
      for (let i = 0; i < 4; i++) {
        this.idenntifyCode += this.idenntifyCodes[
          this.randomNum(0, this.idenntifyCodes.length)
        ];
      }
    },

    // 获取验证码图片
    async getImage() {
      const res = await this.$http.get("/api/captchaImage");
      if (res.code === 200) {
        // (this.obj.image = ),
        // (this.obj.uuid = res.uuid);
        // this.obj = {
        //   image: "data:image/png;base64," + res.img,
        //   uuid: res.uuid,
        // };
        document.querySelector(".image").src =
          "data:image/png;base64," + res.img;
        this.uuid = res.uuid;
        this.loginUser.code = res.captcha;
      }
    },
		// VIP中英文切换显示
		handleCommand(type){
			const loading = this.$loading({
				lock:true,
				text:'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			})
			
			changeLanguage(type);
			localStorage.setItem('vipLang',type);
			
			setTimeout(function(){
				loading.close();
			},1500)
		},
  },
  mounted() {
    this.getImage();
    this.refreshCode();
  },
  async created() {
    // const res = await this.$http.get("/captchaImage");
    // console.log(res);
		
  }
};
</script>
<style scoped>
.el-button:hover {
  margin: 0 !important;
}
.login-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
	background: url(../assets/images/loginBg.png) no-repeat top left;
	background-size: 100% 100%;
  overflow: hidden;
}
.login-header{
	width: 100%;
	height: 120px;
	line-height: 60px;
	position: fixed;
	top: 0px;
}
.login-logo{
	float: left;
	height: 120px;
}
.login-logo img{
	height: 120px;
}
.typecap {
  display: flex;
}
.login_title{
	font-size: 24px;
	text-align: center;
	margin-bottom: 20px;
	color: white;
	font-weight: bold;
	letter-spacing: 2px;
}
.login_slogan{
	/* width: 400px; */
	height: 150px;
	position: absolute;
	left: 20%;
	top: 50%;
	transform: translate(-50%,-50%);
}
.slogan_title {
	text-align: center;
}
.slogan_title label{
	font-size: 54px;
}
.slogan_title label:first-child{
	color: white;
}
.slogan_title i{
	margin: 0 30px;
}
.slogan_title i:before{
	content: '';
	width: 10px;
	height: 10px;
	position: absolute;
	top: 35px;
	display: inline-block;
	background-color: white;
}
.slogan_title label:last-child{
	color: #38d738;
}
.slogan_content{
	font-size: 20px;
	color: #c5c5c5;
	text-align: center;
	margin-top: 30px;
}
.slogan_content span{
	position: relative;
}
.slogan_content span:before{
	content: '';
	display: inline-block;
	width: 20px;
	height: 2px;
	background-color: #c5c5c5;
	position: absolute;
	top: 12px;
	left: -30px;
}
.slogan_content span::after{
	content: '';
	display: inline-block;
	width: 20px;
	height: 2px;
	background-color: #c5c5c5;
	position: absolute;
	top: 12px;
	right: -30px;
}
.container {
  width: 326px;
  padding: 30px 32px;
  position: absolute;
  left: 75%;
  top: 50%;
	transform: translate(-50%,-50%);
  background-color: #ffffff80;
	border-radius: 20px;
}
.container .el-input{
	/* font-size: 16px; */
}
.container /deep/ .el-input__inner{
	background-color: #ffffff00 ;
	color: black;
}
.container /deep/ .el-input__icon{
	color: #139322;
}

/deep/ input:-internal-autofill-selected,
/deep/ input:-internal-autofill-previewed{
	-webkit-text-fill-color: black;
	transition: background-color 100s ease-out 0.2s;
	background-color: #ffffff00 !important;
}
.code {
  width: 60% !important;
  float: left;
}
.indentify {
  float: right;
}
.login-btn {
	font-family: sans-serif;
  width: 100%;
	background-color:#139322;
	outline:none;
	border-color:#ffffff90;
	font-size: 16px;
	letter-spacing: 2px;
	font-weight: bold;
}
.indentify {
  width: 108px;
}
.indentify img {
  width: 100%;
}
.login-btn {
  width: 100%;
}
.forget_password {
  padding-left: 5px;
  text-decoration: none;
  color: #333;
}
.forget_password:hover {
  color: red;
}

[lang='ar'] /deep/ .el-input__prefix {
	right: 5px;
	left: auto;
}
[lang='ar'] /deep/ .el-input__suffix {
	left: 5px;
	right: auto;
}
[lang='ar'] /deep/ .el-input--prefix .el-input__inner{
	padding-right: 30px;
}
[lang='ar'] /deep/ .el-input--suffix .el-input__inner{
	padding-right: 30px;
}
[lang='ar'] .login_slogan{
	left: 30%;
}
[lang='ar'] .slogan_title label{
		font-size: 36px;
	}
[lang='ar'] .slogan_title i{
		margin: 0 40px;
	}

[lang='en'] .login_slogan{
	left: 30%;
}
[lang='en'] .slogan_title label{
		font-size: 36px;
	}
[lang='en'] .slogan_title i{
		margin: 0 40px;
	}
/* 移动设备分辨率样式 */
@media screen and (min-width: 1920px) {
	.login_slogan{
		/* width: 400px; */
		height: 220px;
	}
	.slogan_title label{
		font-size: 64px;
	}
	.slogan_title i{
		margin: 0 40px;
	}
	.slogan_title i:before{
		width: 15px;
		height: 15px;
		top: 40px;
	}
	.slogan_content{
		font-size: 28px;
		margin-top: 40px;
	}
	.slogan_content span:before{
		width: 24px;
		height: 2px;
		top: 16px;
		left: -40px;
	}
	.slogan_content span::after{
		width: 24px;
		height: 2px;
		top: 16px;
		right: -40px;
	}
}


</style>